var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-input",
    {
      directives: [{ name: "uppercase", rawName: "v-uppercase" }],
      staticClass: "iq-input",
      attrs: {
        clearable: _vm.clearable,
        disabled: _vm.disabled,
        placeholder: _vm.placeholder,
        "fetch-suggestions": _vm.options,
      },
      on: { input: _vm.handleInput },
      nativeOn: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.handleKeydownEnter.apply(null, arguments)
        },
      },
      model: {
        value: _vm.inputVal,
        callback: function ($$v) {
          _vm.inputVal = $$v
        },
        expression: "inputVal",
      },
    },
    [
      _vm.isHowSuffixName
        ? _c(
            "template",
            { slot: "suffix" },
            [
              _c("Icon", {
                attrs: { name: _vm.suffixName, width: "20px", height: "20px" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.isHowPrefixName
        ? _c("Icon", {
            attrs: { slot: "prefix", name: "prefixName" },
            slot: "prefix",
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }